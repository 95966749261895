import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import {
  HeroModuleCardCallToAction,
  HeroModuleImage,
  HeroModulePreTitle,
  HeroModuleSubTitle,
  HeroModuleText,
  HeroModuleTitle,
} from '../../innerComponents';
import HeroModuleSummary from '../../innerComponents/HeroModuleSummary';
import { BodyWrapper, CardWrapper, HeroModuleContent } from '../../styles';

export const CardDisplayContent = (props) => {
  const {
    section: {
      cardColour: cardColor,
      customerValuePropositionColour: titleColor,
      primaryCallToActionColour: primaryCTAColor,
      secondaryCallToActionColour: secondaryCTAColor,
      backgroundImageAnchor,
      textColour: textColor,
      subtitleColour: subTitleColor,
      subText: preTitle,
      valueProposition: title,
      titleSize,
      subtitle: subTitle,
      text,
      primaryLink: primaryCTAConfig = {},
      secondaryLink: secondaryCTAConfig = {},
      displayDataAndVenueInfo,
    } = {},
    backgroundPath,
  } = props;

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;
  const alignStyle = themeConfig.module.alignStyle;

  const { xs, sm, offset } =
    dStyles.elements?.content?.alignStyles?.[alignStyle]?.contentGrid;

  return (
    <>
      <HeroModuleImage
        backgroundImage={backgroundPath}
        displayType={backgroundImageAnchor}
      />
      <CardWrapper>
        <div className="container">
          <div className="row">
            <HeroModuleContent
              className={`col-xs-${xs} col-sm-${sm} col-sm-offset-${offset}`}
              colorType={cardColor}
            >
              <BodyWrapper>
                <div>
                  {displayDataAndVenueInfo && <HeroModuleSummary condensed />}
                  <HeroModulePreTitle text={preTitle} />
                  <HeroModuleTitle
                    as={titleSize}
                    text={title}
                    styled
                    colorType={titleColor}
                  />
                  <HeroModuleSubTitle
                    text={subTitle}
                    colorType={subTitleColor}
                  />
                  <HeroModuleText text={text} colorType={textColor} />
                </div>
              </BodyWrapper>
              <HeroModuleCardCallToAction
                primaryCTAConfig={primaryCTAConfig}
                secondaryCTAConfig={secondaryCTAConfig}
                primaryCTAColor={primaryCTAColor}
                secondaryCTAColor={secondaryCTAColor}
              />
            </HeroModuleContent>
          </div>
        </div>
      </CardWrapper>
    </>
  );
};

CardDisplayContent.propTypes = {
  section: PropTypes.shape({
    cardColour: PropTypes.string,
    customerValuePropositionColour: PropTypes.string,
    subtitleColour: PropTypes.string,
    textColour: PropTypes.string,
    subText: PropTypes.string,
    titleSize: PropTypes.string,
    valueProposition: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    primaryLink: PropTypes.object,
    secondaryLink: PropTypes.object,
    primaryCallToActionColour: PropTypes.string,
    secondaryCallToActionColour: PropTypes.string,
    backgroundImageAnchor: PropTypes.string,
  }).isRequired,
  backgroundPath: PropTypes.string,
};
